import Login from "./components/Login/Login";
import TopMostLayout from "./components/TopMostLayout/TopMostLayout";

import {StatusLoginValues, useAuth} from './context/AuthContext';
import Loading from "./components/Loading/Loading";

function AuthenticatedApp() {
    const { statusLogin } = useAuth();

    switch(statusLogin) {
        case StatusLoginValues.UNDEFINED:
        case StatusLoginValues.LOGGED_IN_WAITING_REGISTER:
            return <Loading/>;

        case StatusLoginValues.REGISTER_FAILED:
        case StatusLoginValues.LOGGED_OUT:
            return <Login />;

        case StatusLoginValues.REGISTERED:
            return <TopMostLayout />;
    }
}

export default AuthenticatedApp;
