// /redux/actions/index.js`
// import { myMSALObj } from '../../services/auth';



export const setCurrentProject = (currentProject) => {
    localStorage.setItem('currentProject', JSON.stringify(currentProject));

    return {
        type: 'SET_CURRENT_PROJECT',
        currentProject
    }
}


export const setProjectList = (projectList) => {
    localStorage.setItem('projectList', JSON.stringify(projectList));

    return {
        type: 'SET_PROJECT_LIST',
        projectList
    }
}

export const setChatSlidingWindow = (chatSlidingWindow) => ({
    type: 'SET_SLIDING_WINDOW',
    chatSlidingWindow
})

export const setDataStore = (useDataStore) => ({
    type: 'SET_DATA_STORE',
    useDataStore
})

export const setModel = (useModel) => {
    localStorage.setItem('model', useModel);
    return {
        type: 'SET_MODEL',
        useModel
    }
}

export const setModelList = (modelList) => ({
    type: 'SET_MODEL_LIST',
    modelList
})

export const setTemperature = (temperature) => {
    localStorage.setItem('temperature', temperature);
    return {
        type: 'SET_TEMPERATURE',
        temperature
    }
}

export const setAgent = (useAgent) => ({
    type: 'SET_AGENT',
    useAgent
})


// export const setUsePremiumModel = (usePremiumModel) => ({
//     type: 'SET_USE_PREMIUM_MODEL',
//     usePremiumModel
// })

// export const setProjectCount = (projectCount) => ({
//     type: 'SET_PROJECT_COUNT',
//     projectCount
// });

// export const setLoading = (loading) => ({
//     type: 'SET_LOADING',
//     loading
// });

export const setErrorMessage = (errorMessage) => {
    const error = new Error();
    console.log( 'error message: ', errorMessage);
    console.log( error.stack || error.stacktrace);

    return {
        type: 'SET_ERROR_MESSAGE',
        errorMessage
    }
}

export const setInfoMessage = (infoMessage) => ({
    type: 'SET_INFO_MESSAGE',
    infoMessage
});

export const setCurrentChatId = (chatId) => ({
    type: 'SET_CURRENT_CHAT_ID',
    chatId
})

export const setShowNav = (showNav) => ({
    type: 'SET_SHOW_NAV',
    showNav
})

export const setIsMobile = (isMobile) => ({
    type: 'SET_IS_MOBILE',
    isMobile
})

export const setRetryLogin = (retryLogin) => ({
    type: 'SET_RETRY_LOGIN',
    retryLogin
})

export const setForceLogin = (isForceLogin) => ({
    type: 'SET_FORCE_LOGIN',
    isForceLogin
})

export const setUseMaximize = (useMaximize) => ({
    type: 'SET_USE_MAXIMIZE',
    useMaximize
})

export const setBalance = (balance) => ({
    type: 'SET_BALANCE',
    balance
})
export const setCurrentUsage = (currentUsage) => ({
    type: 'SET_CURRENT_USAGE',
    currentUsage
})

export const setChatLayout = (chatLayout) => {
    localStorage.setItem('layout', chatLayout);

    return {
        type: 'SET_CHAT_LAYOUT',
        chatLayout
    }
}

export const setDisableFromat = (isDisableFormat) => {
    localStorage.setItem('isDisableFormat', isDisableFormat);

    return {
        type: 'SET_DISABLE_FORMAT',
        isDisableFormat
    }
}

// export const setShowSysMessage = (showSysMessage) => {
//     localStorage.setItem('showSysMessage', showSysMessage);
//
//     return {
//         type: 'SET_SHOW_SYS_MESSAGE',
//         showSysMessage
//     }
// }

export const setShowOnTop = (showOnTop) => {
    localStorage.setItem('showOnTop', showOnTop);

    return {
        type: 'SET_SHOW_ON_TOP',
        showOnTop
    }
}


export const setProfile = (profile) => ({
    type: 'SET_PROFILE',
    profile
})

// export const setLoggedUsing = (loggedUsing) => {
//     localStorage.setItem('logged-using', loggedUsing);
//     console.log('setLoggedUsing to : ', loggedUsing);
//
//     return {
//         type: 'SET_LOGGED_USING',
//         loggedUsing
//     }
// }


// export const setToken = (token) => ({
//     type: 'SET_TOKEN',
//     token
// })

export const setWaiting = (waiting) => ({
    type: 'SET_WAITING',
    waiting
})

